// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/_hooks/useTranslation.ts"
);
import.meta.hot.lastModified = "1737466976000";
}
// REMIX HMR END

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useWorkspaceData from './useWorkspace';

/**
 * Custom hook to load the given namespace with useTranslation.
 *
 * @param namespace - The namespace to be loaded dynamically.
 * @returns The translation functions from react-i18next.
 */
export const useTranslations = (namespace: string) => {
  const { t } = useTranslation(namespace);
  return { t };
};

/**
 * Language Detector For the
 * Entire Application
 */
export const useAppLanguageDetector = () => {
  const { translations, locale } = useWorkspaceData();

  // useEffect(() => {
  //   i18n.changeLanguage(userLang);
  // }, [userLang]);
  return { translations, locale };
};
