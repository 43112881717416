// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/i18n.ts"
);
import.meta.hot.lastModified = "1737916739000";
}
// REMIX HMR END

export default {
  supportedLngs: ['en', 'es'],
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  // debug: true,
  ns: ['headings', 'tableHeaders', 'form', 'message', 'dashboard'],
  defaultNS: 'translation',
};
