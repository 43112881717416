// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/_hooks/useWorkspace.ts"
);
import.meta.hot.lastModified = "1737466976000";
}
// REMIX HMR END

import { useRevalidator, useRouteLoaderData } from '@remix-run/react';
import { useEffect, useMemo } from 'react';
import { useInterval } from 'usehooks-ts';

import { ENTITY_TYPE } from '~/lib/constants';

type IAvailableWorkspace = {
  name: string;
  appId: string;
  id: string;
};

/**
 * Hook For Fetching the Main
 * App Route Loader Data
 * @returns object
 */
const useWorkspaceData = () => {
  const data = useRouteLoaderData('routes/($lang).app_.$workspaceId') as any;

  const workspace = data?.activeWorkspace?.workspaceId;
  const workspaceEntities = data?.activeWorkspace?.entityIds || [];
  const workspaceVeritcals = data?.activeWorkspace?.workspaceVerticals || [];
  const workspaceUserMenus = data?.workspaceUserMenu || [];
  const acceptedUserWorkspaces = data?.acceptedWorkspace ?? [];
  const translations=data.translations
  const locale=data.locale
  const workspaceList = useMemo(
    () =>
      acceptedUserWorkspaces?.map(
        (availableWorkspace: IAvailableWorkspace) => ({
          name: availableWorkspace.name,
          code: availableWorkspace.appId,
          type: ENTITY_TYPE.Workspace,
          id: availableWorkspace.id,
        }),
      ) || [],
    [acceptedUserWorkspaces],
  );

  return {
    workspace,
    workspaceList,
    workspaceEntities,
    workspaceVeritcals,
    workspaceUserMenus,
    userLang: data?.appLanguage,
    translations,
    locale

  };
};

export default useWorkspaceData;

/**
 * Hook For Polling the Root Route
 */
export const usePolling = (routeName: string) => {
  const revalidator = useRevalidator();

  useInterval(() => revalidator.revalidate(), routeName ? 30 * 1000 : null);

  useEffect(() => {
    if (revalidator.state === 'loading')
      console.log(`Revalidating ${routeName}`);
  }, [revalidator.state]);
};
