// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/_layout/Public/PublicLayout.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/_layout/Public/PublicLayout.tsx");
  import.meta.hot.lastModified = "1737916739000";
}
// REMIX HMR END

import Header from '../_common/_includes/Header';
import Footer from '../_common/_includes/Footer';
export default function PublicLayout({
  children
}) {
  return <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>;
}
_c = PublicLayout;
var _c;
$RefreshReg$(_c, "PublicLayout");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;