// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/lib/lang/en.ts\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/lib/lang/en.ts");
  import.meta.hot.lastModified = "1737916739000";
}
// REMIX HMR END

/** Menu Names **/

export const HELP = 'Help';
export const FAQ = 'FAQ';
export const USER_GUIDE = 'User Guide';
export const HOME = 'Home';
export const LOGIN = 'Login';
export const DASHBOARD = 'Dashboard';
export const SETTINGS = 'Settings';
export const USER_MANAGEMENT = 'User Management';
export const USERS = 'Users';
export const ROLES = 'Roles';
export const ENTITY_MANAGEMENT = 'Entity Management';
export const GROUP_PROFILE = 'Group Profile';
export const AUDIT_LOG = 'Audit Log';
export const DASHBOARD_CONFIGURATION = 'Dashboard Configuration';
export const CONFIGURATION = 'Configuration';
export const DATA_UPLOAD = 'Data Upload';
export const REPORT = (plural = false) => `Report${plural ? `s` : ''}`;
_c = REPORT;
export const MATURITY = 'Maturity';
export const MATERIALITY = 'Materiality';
export const KPI_DEFINITION = 'KPI Definition';
export const DYNAMIC_REPORT = 'Dynamic Reports';
export const TARGET_DEFINITION = 'Target Definition';
export const STEWARDSHIP_MASTER = 'Stewardship Master';
export const VALUE_CHAIN_MASTER = 'Value Chain Master';
export const EMISSION = 'Emission';
export const UNIT_CONVERSION = 'Unit Conversion';
export const CONVERSION_FACTOR = 'Conversion Factor';
export const EPA_CONVERSION_FACTOR = 'EPA Conversion Factor';
export const IMO = 'IMO';
export const GWP = 'GWP';
export const IATA_CONVERSION_FACTORS = 'IATA';
export const SOURCE_CONVERSION_FACTOR = 'IPCC';
export const ASSESSMENT = 'Assessment';
export const EMISSION_CALCULATION = 'Emission Calculations';
export const CARBON_FOOTPRINT_INV = 'Carbon Footprint-Inv';
export const AUTHORIZATION_HISTORY = 'Authorization History';
export const EMISSION_CALCULATION_DUMMY = 'Emission Calculations Dummy';
export const ESG_REPORT = 'ESG Report';
export const KPI_REPORT = 'KPI Report';
export const DISCLOSURE_REPORT = 'Disclosure Report';
export const TARGET_TRACKING = 'Target Tracking';
export const STEWARDSHIP_ACTIVITIES = 'Stewardship Activities';
export const PORTFOLIO_CARBON_FOOTPRINT = 'Portfolio Carbon Footprint';
export const KNOWLEDGE_ASSET = 'Knowledge Asset';
export const APPLICATION_TOKEN = 'API Token';
export const ORGANIZATION_MANAGEMENT = 'Organization Management';
export const AUTHORIZATION = 'Authorization Workflow Management';
export const VALUE_CHAIN = 'Value Chain';
export const DATA = 'Data';
export const POWERBI_REPORT = 'Power BI';
export const POWERBI_TRANING = 'Training Report';
export const POWERBI_GNEDER_REMUNERATION = 'Gender & Remuneration Report';
var _c;
$RefreshReg$(_c, "REPORT");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;