// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Slider/_provider/SliderContext.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Slider/_provider/SliderContext.tsx");
  import.meta.hot.lastModified = "1737916739000";
}
// REMIX HMR END

import { createContext, useRef, useContext } from 'react';
import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
// Emission Form Store State And Actions
const createAppFormStore = initialState => {
  return createStore()(set => ({
    ...initialState,
    setIsOpen: isOpen => set(() => ({
      isOpen: isOpen
    })),
    setActionType: actionType => set(() => ({
      actionType: actionType
    })),
    setUpdateData: updatedata => set(() => ({
      updatedata: updatedata
    }))
  }));
};

// Emission Store Api Type

// Emission Store Context
const AppStoreContext = createContext(null);

// Emission Store Provider
export const AppStoreProvider = ({
  children,
  value
}) => {
  _s();
  const storeRef = useRef();
  if (!storeRef.current) {
    storeRef.current = createAppFormStore({
      ...value
    });
  }
  return <AppStoreContext.Provider value={storeRef.current}>
      {children}
    </AppStoreContext.Provider>;
};

// Custom Hook For Using the Emission Store Context
_s(AppStoreProvider, "EtiU7pDwGhTDZwMnrKEqZbxjqXE=");
_c = AppStoreProvider;
export const useAppStoreContext = selector => {
  _s2();
  const appStoreContext = useContext(AppStoreContext);
  if (!appStoreContext) {
    throw new Error(`useAppStoreContext must be use within AppStoreProvider`);
  }
  return useStore(appStoreContext, selector);
};
_s2(useAppStoreContext, "ykQaDsTW4vcigEpanjgzThfowdo=", false, function () {
  return [useStore];
});
var _c;
$RefreshReg$(_c, "AppStoreProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;